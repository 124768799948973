.about .swiper-container {
  width: 100%;
}

.about .swiper-wrapper {
  margin-bottom: 40px;
}

.manual .swiper-wrapper {
  margin-bottom: 40px;
}